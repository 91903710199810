.container {
    @apply relative;

    width: calc(100% + 5px) !important;
    border-radius: 0.1875rem;
}

.date_picker {
    @apply py-2 ps-8 pe-9 font-medium cursor-pointer border transition focus:border-primary active:border-primary outline-none !text-xs;
    line-height: 0 !important;
}

.date_icon {
    @apply absolute top-1/2 -translate-y-1/2 first-of-type:z-10 pointer-events-none cursor-pointer;
}

.date_picker_standard {
    @apply text-dark;

    background-color: #F7F7F7;
}

.date_icon_standard {
    @apply text-dark;
}

.date_picker_primary {
    @apply text-primary;

    background-color: #E7F3FD;
}

.date_icon_primary {
    @apply text-primary;
}