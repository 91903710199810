/** Start Custom Table */

.custom_table {
	@apply table-fixed w-full;
}

.custom_table thead tr {
	@apply bg-gray-200 text-gray-800;
}

.custom_table thead th {
  @apply font-semibold capitalize;
}

.custom_table thead th div {
  @apply flex justify-center items-center;
}

.custom_table tbody {
  @apply text-dark divide-y divide-lightGray bg-white;
}

.custom_table tbody td {
  @apply 2xl:text-lg 3xl:text-xl text-center 2xl:py-5 3xl:py-6 capitalize;
}

/** End Custom Table */
/** Start Align start */

.align_table_start thead th {
  @apply !px-6 text-start;
}

.align_table_start thead th div {
  @apply justify-start;
}

.align_table_start tbody td {
  @apply text-start !px-6 text-sm;
}

/** End Align start */
/** Start Table sizes */

.table_md thead th,
.table_md tbody td {
  @apply py-2;
}

.table_lg thead th,
.table_lg tbody td {
  @apply py-3;
}

.table_xl thead th,
.table_xl tbody td {
  @apply py-4 px-1;
}

/** End Table sizes */
/** Start Table group */

.table_group thead th {
  @apply border border-lightGray border-collapse py-1;
}

/** End Table group */
/** Start Table borders */

.table_borders thead th,
.table_borders tbody td {
  @apply border border-lightGray border-collapse;
}

/** End Table borders */
/** Start Table no borders */

.no_borders {
  border-collapse: separate;
  border-spacing: 0 1rem ;
  margin-top:-1rem
}

.no_borders thead th,
.no_borders tbody td{
 @apply text-dark text-sm font-semibold py-3 text-center ;
}

.no_borders td:first-child {
  @apply rounded-l-lg
}

.no_borders td:last-child {
  @apply rounded-r-lg
}

/** End Table no borders */
/** Start Table add row */

table tr.add_row td input.input-basic {
  @apply h-full pl-7 py-1 focus:outline-none border-b border-lightGray hover:border-primary focus:border-primary -mb-1 placeholder:text-xs
}

table tr.add_row td{
  padding-top: 6 !important;
  padding-bottom: 0 !important;
  padding-left: 0!important;
  text-align: center;
} 

/** End Table add row */
