input[type="checkbox"].checkboxInput:checked:not(:disabled) + label.customLabel {
  @apply bg-primary border-primary  !important;
}

input[type="checkbox"].checkboxInput:checked + label span.checkmark {
  position:  absolute;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[type="checkbox"].checkboxInput:checked + label span.checkmarkMedium {
  left: 8px;
  top: 4px;
}

input[type="checkbox"].checkboxInput:checked + label span.checkmarkSmall {
  left: 6px;
  top: 2px;
}

input[type="checkbox"].checkboxInput:checked + label span.checkmarkXSmall {
  left: 4px;
  top: 1px;
}