.container {
	@apply flex justify-between gap-5 mb-9;
}

.step {
	@apply flex items-center gap-4 flex-col;
}

.number {
	@apply text-xl font-normal leading-[1.1875rem] text-[#BDBDBD] w-8 h-8 rounded-full flex items-center justify-center;
	border: 3px solid #BDBDBD;
}

.number_current {
	@apply text-primary border-primary;
}

.number_done {
	@apply text-white border-primary bg-primary;
}

.name {
	@apply text-xl font-semibold leading-[1.1875rem] text-[#BDBDBD] block w-[112px] text-center;
}

.name_current {
	@apply text-primary;
}

.line_container {
	@apply relative w-full h-1.5;
	/** ( Step Height / 2 - Line height / 2) */
	top: calc(2rem / 2 - 0.375rem / 2);
}

.line {
	@apply h-full bg-[#BDBDBD] rounded-full absolute top-0 left-1/2 transform -translate-x-1/2;
	width: 90px;
}

.line_active {
	@apply bg-primary;
}