@tailwind base;
@tailwind components;
@tailwind utilities;

/**adding Fonts*/
@layer base {
  @font-face {
    font-family: "Cairo";
    font-weight: 400;
    src: url("src/assets/fonts/Cairo-Regular.ttf") format("ttf");
  }
  @font-face {
    font-family: "Cairo";
    font-weight: 600;
    src: url("src/assets/fonts/Cairo-SemiBold.ttf") format("ttf");
  }
  @font-face {
    font-family: "Cairo";
    font-weight: 700;
    src: url("src/assets/fonts/Cairo-Bold.ttf") format("ttf");
  }
}
/********adding utilities classes************/

body {
  @apply font-cairo ;
  /* overflow-y: hidden */
  /* font-family: "Cairo"; */
}
.center {
  @apply flex justify-center items-center;
}
.align-center {
  @apply flex items-center;
}


/************** CSS Trnasition ********/

.transition-enter {
  opacity: 0;
  transform: scale(0.9);
}
.transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms ease-in, transform 200ms ease-in;
}
.transition-exit {
  opacity: 1;
}
.transition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 200ms ease-out, transform 200ms ease-out;
}
.show-enter {
  opacity: 0;
}
.show-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in
}
.show-exit {
  opacity: 1;
}
.show-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-out
}
/* .transition-enter {
  opacity: 0;
}
.transition-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.transition-exit {
  opacity: 1;
}
.transition-exit-active {
  opacity: 0;
  transition: opacity 200ms;
} */
/************** CSS Trnasition ********/

@layer components {
  .scrollBar::-webkit-scrollbar {
    @apply w-[10px];
    overscroll-behavior: auto;
  }

  .scrollBar::-webkit-scrollbar-track {
    @apply bg-background shadow-inner;
  }

  .scrollBar::-webkit-scrollbar-thumb {
    @apply border-r-8 bg-gray-400;

  }
  .scrollBar::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-600;
  }

}

.noscroll::-webkit-scrollbar {
  display: none;
}