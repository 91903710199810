input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.input {
  @apply block w-full appearance-none placeholder-gray-600 text-sm 2xl:text-base 3xl:text-lg text-dark focus:outline-none  read-only:focus:border-0 ;
}

.input::placeholder {
  @apply text-xs;
}

.input--filled {
  @apply bg-background rounded-md focus:rounded-t-md focus:rounded-b-none focus:border-b focus:-mb-px focus:border-primary;
}

.input--outlined {
  border: 1px solid #C2C2C2;
  border-radius: 4px;
  @apply bg-nativeWhite border focus:border-primary;
}

.input--outlined::placeholder {
  @apply text-sm leading-5;
  color: #C2C2C2;
}

.input--standard {
  @apply border-b border-gray-400 focus:border-primary;
}

.input--basic {
  @apply text-gray-800 placeholder-transparent focus:placeholder-gray-200 border border-background rounded-xl py-3 leading-tight focus:outline-none bg-background;
}

.input--standard.input--error {
  @apply border-red2 !important;
}

.input--basic.input--error {
  @apply border-red2 !important;
}

.input--filled.input--error {
  @apply border-red2 border-b rounded-t-md rounded-b-none -mb-px !important;
}
