  .rbc-day-slot .rbc-events-container {
  @apply ms-0 me-0 !important;
}

.rbc-allday-cell {
  @apply hidden !important;
}

.rbc-time-view .rbc-header {
  @apply font-cairo text-gray-700 py-2 2xl:py-3 3xl:py-4  border-b-0 !important;
}

.rbc-time-header-cell-single-day {
  @apply block !important;
}

/* .rbc-time-header-content > .rbc-time-header-cell-single-day {
  @apply hidden !important;
} */

.rbc-today {
  @apply bg-white !important;
}

.rbc-time-header {
  position: sticky;
  top: 1px;
  z-index: 10;
  outline: 1px solid #ddd;
  background-color: white;
} 

.rbc-event {
  border: 1px solid #d9dddf !important;
}


.rbc-event-content {
  padding: 5px 5px;
}

.rbc-header {
  margin: auto;
}

/* .rbc-time-view-resources .rbc-day-slot, .rbc-header {
  min-width: 200px !important;
} */

.rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter {
  z-index: 9 !important;
}
