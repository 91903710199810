@import "react-toastify/dist/ReactToastify.css";
@import "src/styles/date-picker.css";
@import "src/styles/table.css";
@import "src/styles/input.css";

.dropdown {
  @apply w-full flex items-center justify-between cursor-pointer focus:outline-none;
}

.label {
  @apply inline-block text-dark opacity-80 text-start text-sm 2xl:text-lg 3xl:text-xl;
}

.label-basic {
  @apply absolute z-30 text-gray-800 left-3 top-2.5 px-2 rounded-lg transition-all opacity-100;
}

.label-phone {
  @apply absolute z-30 text-gray-800 left-3 top-2.5 px-2 rounded-lg transition-all opacity-100;
}

.dropMenu {
  @apply absolute top-full max-h-60 overflow-y-auto bg-white z-50 rounded-b-xl shadow-lg;
}

/* truncate multiple line*/
.truncate-mutiple{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@page {
  size: A4;
  margin:0.25in 2cm ;
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }

  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
  }
}